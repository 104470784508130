<template>

  <div class="invoice-box">
    <table
      cellpadding="0"
      cellspacing="0"
    >
      <tr class="top">
        <td colspan="4">
          <table>
            <tr>
              <td
                class="title"
                style="text-align: center; vertical-align: top; width: 33%; padding-top: 0;"
              >
                <h2>
                  {{ invoiceData.content.company_info.name }}
                </h2>
                <img
                  v-if="invoiceData.design.logo.file"
                  :src="invoiceData.design.logo.file.url"
                  :style="'width: 100%; margin-left:'+invoiceData.design.logo.align+'mm; max-width: '+(invoiceData.design.logo.size=='sm'?'100px':invoiceData.design.logo.size=='md'?'200px':'300px')"
                >
              </td>

              <td style="width: 33%;" />

              <td style="text-align: left; vertical-align: top; width: 33%; padding-top: 0;">
                <h1
                  style="margin-top: 0;"
                  :style="{color:invoiceData.design.themeColor}"
                >
                  <strong>
                    Invoice

                  </strong>
                </h1>
                <strong>
                  {{ labels.document_headings.filter(a => a.value == 'invoice_no')[0].input }} #: {{ invoiceData.design.options.numbers.invoiceNumberPrefix }}{{ invoiceData.content.invoice_number }}<br>
                </strong>
                {{ labels.document_headings.filter(a => a.value == 'invoice_date')[0].input }}: {{ invoiceData.content.invoice_date }}<br>
                <span v-if="invoiceData.design.options.header.showDueDate">
                  {{ labels.document_headings.filter(a => a.value == 'due_date')[0].input }}: {{ invoiceData.content.due_date }}
                </span><br>
                <span v-if="invoiceData.content.company_info.gst">GST: {{ invoiceData.content.company_info.gst }}</span><br>
                <span v-if="invoiceData.content.company_info.wbc">WCB: {{ invoiceData.content.company_info.wbc }}</span>
              </td>
            </tr>
          </table>
        </td>
      </tr>

      <tr class="information">
        <td colspan="4">
          <table>
            <tr>
              <td>

                <table style="font-size:10px;">
                  <tr>
                    <td>
                      <strong>
                        {{ labels.document_headings.filter(a=>a.value=='bill_to')[0].input }}:

                      </strong>
                    </td>
                    <td
                      v-if="invoiceData.content.billing"
                      style="text-align: left;"
                    >

                      <strong>
                        {{ invoiceData.content.billing.name }}
                      </strong>
                      <br>
                      <span
                        v-if="invoiceData.content.billing.email"
                        class="inline-span"
                      >{{ invoiceData.content.billing.email }} </span>
                      <p
                        style="max-width: 500px; word-wrap: break-word; white-space: normal;
                        overflow: hidden; text-overflow: ellipsis;
                        text-align: left;"
                        class="margin-bottom-0"
                      >
                        {{ invoiceData.content.billing.address }}
                      </p>
                      <span
                        v-if="invoiceData.content.billing.phone"
                        class="inline-span"
                      >{{ invoiceData.content.billing.phone }}</span>
                      <span class="inline-span">{{ invoiceData.content.billing.mobile }}</span>
                    </td>
                  </tr>
                </table>

              </td>

              <td />
            </tr>
          </table>
          <table style="width:100%">
            <tr
              v-for="field,index in invoiceData.design.options.header.customFields"
              :key="index"
            >
              <td>
                <span><strong>{{ field.name }}</strong></span>
              </td>
              <td style="text-align:left;">
                {{ field.value }}
              </td>
            </tr>
          </table>
        </td>
      </tr>

    </table>
    <table style="width: 100%; border-spacing: 0; border-collapse: collapse;">
      <!-- Header Row -->
      <tr
        class="heading"
        :style="{'background-color': invoiceData.design.themeColor}"
        style="color:white;"
      >
        <td
          v-if="invoiceData.design.options.table.showItemCode"
          style="padding:8px;"
        >
          {{ labels.general.filter(a => a.value == 'code')[0].input }}
        </td>
        <td style="width: 400px; text-align: left; padding:8px;">
          {{ labels.general.filter(a => a.value == 'description')[0].input }}
        </td>
        <td
          v-if="invoiceData.design.options.table.showQuantityAndRate"
          style="text-align: center; padding:8px;"
        >
          {{ labels.general.filter(a => a.value == 'quantity')[0].input }}
        </td>
        <td
          v-if="invoiceData.design.options.table.showQuantityAndRate"
          style="text-align: center; padding:8px;"
        >
          {{ labels.general.filter(a => a.value == 'unit_price')[0].input }}
        </td>
        <td
          v-for="singleTax, index in invoiceData.content.totals.taxes"
          :key="index"
          style="text-align: center; padding:8px;"
        >
          <span v-if="invoiceData.design.options.table.showTax"> {{ singleTax.tax_name }}</span>
        </td>
        <td style="text-align: right; padding:8px;">
          {{ labels.general.filter(a => a.value == 'amount')[0].input }}
        </td>
      </tr>

      <!-- Item Rows -->
      <tr
        v-for="(item, index) in invoiceData.content.items"
        :key="index"
        class="item"
        style="border-bottom: 1px solid #ddd;"
      >
        <td
          v-if="invoiceData.design.options.table.showItemCode"
          style="padding:8px;"
        >
          {{ item.code }}
        </td>
        <td style="text-align: left; padding:8px;">
          <p> {{ item.name }}</p>
          <p
            v-if="item.description"
            style="color: #888;"
          >
            {{ item.description }}
          </p>
        </td>
        <td
          v-if="invoiceData.design.options.table.showQuantityAndRate"
          style="text-align: center; padding:8px;"
        >
          {{ item.quantity }}
        </td>
        <td
          v-if="invoiceData.design.options.table.showQuantityAndRate"
          style="text-align: center; padding:8px;"
        >
          {{ invoiceData.content.currency_symbol }} {{ $staticParams.formatCurrency(item.unit_price) }}
        </td>
        <td
          v-for="(singleTax, taxIndex) in invoiceData.content.totals.taxes"
          :key="taxIndex"
          style="text-align: center; padding:8px;"
        >
          <span v-if="invoiceData.design.options.table.showTax"> {{ getItemTaxRate(item, singleTax) }}%</span>
        </td>
        <td style="text-align: right; padding:8px;">
          {{ invoiceData.content.currency_symbol }} {{ $staticParams.formatCurrency(item.amount) }}
        </td>
      </tr>
    </table>

    <!-- Subtotal, Tax, and Total Rows -->
    <table style="width: 100%; margin-top: 20px; border-collapse: collapse;">
      <tr>
        <!-- Left side for Comments -->
        <td style="width: 55%; vertical-align: top;">
          <div v-if="invoiceData.design.options.footer.comments.genericComment">
            <h5 style="font-weight: bold;">
              {{ labels.document_summary.filter(a => a.value == 'notes')[0].input }}
            </h5>
            <p>{{ invoiceData.design.options.footer.comments.genericComment }}</p>
          </div>

          <div v-if="invoiceData.design.options.footer.comments.termsAndConditions">
            <h5 style="font-weight: bold;">
              {{ labels.document_summary.filter(a => a.value == 'terms_and_condictions')[0].input }}
            </h5>
            <p>{{ invoiceData.design.options.footer.comments.termsAndConditions }}</p>
          </div>
        </td>

        <!-- Right side for Subtotal, Discount, Tax, and Total -->
        <td style="width: 45%; vertical-align: top;">
          <table style="width: 100%; border-spacing: 0; border-collapse: collapse;">
            <!-- Subtotal Row -->
            <tr>
              <td style="text-align: right; padding:8px;">
                Subtotal
              </td>
              <td style="text-align: right; padding:8px;">
                {{ invoiceData.content.currency_symbol }} {{ $staticParams.formatCurrency(invoiceData.content.totals.subtotal) }}
              </td>
            </tr>

            <!-- Discount Row -->
            <tr v-if="invoiceData.content.totals.discount > 0">
              <td style="text-align: right; padding:8px;">
                Discount
              </td>
              <td style="text-align: right; padding:8px;">
                {{ invoiceData.content.currency_symbol }} {{ $staticParams.formatCurrency(invoiceData.content.totals.discount) }}
              </td>
            </tr>

            <!-- Tax Rows -->
            <tr
              v-for="(singleTax, taxIndex) in invoiceData.content.totals.taxes"
              :key="taxIndex"
            >
              <td style="text-align: right; padding:8px;">
                {{ singleTax.tax_name }} ({{ singleTax.tax_rate }}%)
              </td>
              <td style="text-align: right; padding:8px;">
                {{ invoiceData.content.currency_symbol }} {{ $staticParams.formatCurrency(singleTax.amount) }}
              </td>
            </tr>

            <!-- Grand Total Row -->
            <tr
              style="font-weight: bold; font-size: 24px; color:white;"
              :style="{ 'background-color': invoiceData.design.themeColor }"
            >
              <td style="text-align: right; padding:8px;">
                Total
              </td>
              <td style="text-align: right; padding:8px;">
                {{ invoiceData.content.currency_symbol }} {{ $staticParams.formatCurrency(invoiceData.content.totals.total) }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <table
      v-if="invoiceData.design.options.footer.showSignatureLine"
      style="margin-top:75px;"
    >
      <tr>
        <td style="text-align:center; border-top: 1px solid gray;  width: 50%;">

          <h5 style="font-weight:bold; margin-top: 10px;">
            {{ labels.document_summary.filter(a=>a.value=='company_signature')[0].input }}
          </h5>
        </td>
        <td />
        <td style="text-align:center; border-top: 1px solid gray;  width: 50%;">
          <h5 style="font-weight:bold; margin-top: 10px;">
            {{ labels.document_summary.filter(a=>a.value=='client_signature')[0].input }}
          </h5>
        </td>
      </tr>

    </table>

    <table style="width: 100%; border-spacing: 0; font-size:10px; margin-top:75px;">
      <tr class="d-flex align-items-center justify-content-between">

        <!-- Email & Website Section -->
        <td
          class="d-flex justify-content-left align-items-center mr-1"
          style="text-align: left !important;"
        >
          <span
            class="d-flex justify-content-center align-items-center mr-2"
            style="width: 30px; height: 30px; border-radius: 50%; color: white; flex-shrink: 0; margin-right: 5px;"
            :style="{'background-color':invoiceData.design.themeColor}"
          >
            <feather-icon
              icon="MailIcon"
              size="14"
            />
          </span>

          <div style="flex-grow: 1;">
            <p style="margin: 0;">
              {{ invoiceData.content.company_info.email }}
            </p>
            <p style="margin: 0;">
              {{ invoiceData.content.company_info.website }}
            </p>
          </div>
        </td>

        <!-- Phone Section -->
        <td class="d-flex justify-content-left align-items-center">
          <span
            class="d-flex justify-content-center align-items-center mr-2"
            style="width: 30px; height: 30px; border-radius: 50%; color: white; flex-shrink: 0; margin-right: 5px;"
            :style="{'background-color':invoiceData.design.themeColor}"
          >
            <feather-icon
              icon="PhoneIcon"
              size="14"
            />
          </span>

          <div
            style=" flex-grow:1; max-width: 200px; word-wrap: break-word; white-space: normal;
                        overflow: hidden; text-overflow: ellipsis;
                        text-align: left;"
          >
            <span>{{ invoiceData.content.company_info.phone }}</span>
          </div>
        </td>

        <!-- Company Name & Address Section -->
        <td class="d-flex justify-content-left align-items-center mr-1">
          <span
            class="d-flex justify-content-center align-items-center mr-2"
            style="width: 30px; height: 30px; border-radius: 50%; color: white; flex-shrink: 0; margin-right: 5px;"
            :style="{'background-color':invoiceData.design.themeColor}"
          >
            <feather-icon
              icon="MapIcon"
              size="14"
            />
          </span>

          <div
            style=" flex-grow:1; max-width: 200px; word-wrap: break-word; white-space: normal;
                        overflow: hidden; text-overflow: ellipsis;
                        text-align: left;"
          >
            <strong>{{ invoiceData.content.company_info.name }}</strong>
            <p
              style="margin: 0; max-width:90%; word-wrap: break-word; white-space: normal;
                 overflow: hidden; text-overflow: ellipsis; text-align: left;"
            >
              {{ invoiceData.content.company_info.address }}
            </p>
          </div>
        </td>

      </tr>
      <tr colspan="3">
        <p
          style="margin-top: 10px; max-width:95%; word-wrap: break-word; white-space: normal;
                 overflow: hidden; text-overflow: ellipsis; text-align: left;"
        >
          {{ invoiceData.content.company_info.additional_information }}
        </p>
      </tr>
    </table>

  </div>

</template>

<script>

export default {
  components: {
  },
  props: {
    invoiceData: {
      type: Object,
      required: true,
    },

  },
  data() {
    return {

    }
  },
  computed: {
    labels() {
      return this.invoiceData.design.options.labels
    },
  },
  methods: {
    getItemTaxRate(item, taxObj) {
      const tax = item.applied_taxes.filter(f => f.tax_id === taxObj.tax_id)
      if (tax.length > 0) {
        return tax[0].tax_rate//
      }

      return 0
    },
  },
}
</script>
 <style scoped>
        .invoice-box {
       width:210mm;
           min-height: 11in;
        margin: auto;
        padding: 30px;
        border: 1px solid #eee;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        font-size: 12px;
        line-height: 24px;
        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
        color: #555;
        }

        .invoice-box table {
        width: 100%;
        line-height: inherit;
        text-align: left;
        }

        .invoice-box table td {
        padding: 1px;
        vertical-align: top;
        }

        .invoice-box table tr td:nth-child(2) {
        text-align: right;
        }

        .invoice-box table tr.top table td {
        padding-bottom: 20px;
        }

        .invoice-box table tr.top table td.title {
        font-size: 45px;
        line-height: 45px;
        color: #333;
        }

        .invoice-box table tr.information table td {
        padding-bottom: 40px;
        }

        .invoice-box table tr.heading td {

        border-bottom: 1px solid #ddd;
        font-weight: bold;
        }

        .invoice-box table tr.details td {
        padding-bottom: 20px;
        }

        .invoice-box table tr.item td {
        border-bottom: 1px solid #eee;
        }

        .invoice-box table tr.item.last td {
        border-bottom: none;
        }

        .invoice-box table tr.total td:nth-child(2) {
        border-top: 2px solid #eee;
        font-weight: bold;
        }

        /** RTL **/
        .invoice-box.rtl {
        direction: rtl;
        font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
        }

        .invoice-box.rtl table {
        text-align: right;
        }

        .invoice-box.rtl table tr td:nth-child(2) {
        text-align: left;
        }
        .inline-span{
          display: block;
        }
        .margin-bottom-0{
          margin-bottom:0 !important;
        }
      </style>
